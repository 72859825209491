(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/outcome-pair/assets/javascripts/outcome-pair.js') >= 0) return;  svs.modules.push('/components/lb-ui/outcome-pair/assets/javascripts/outcome-pair.js');
"use strict";


const {
  Outcome
} = svs.components.lbUi.outcome;
const OutcomePair = _ref => {
  let {
    outcomeHome,
    outcomeAway,
    className
  } = _ref;
  const classNames = ['pg_outcome_pair'];
  if (className) {
    classNames.push(className);
  }
  return React.createElement("div", {
    className: classNames.join(' ')
  }, React.createElement(Outcome, {
    className: className,
    isSelected: true,
    sign: outcomeHome
  }), "-", React.createElement(Outcome, {
    className: className,
    isSelected: true,
    sign: outcomeAway
  }));
};
setGlobal('svs.components.lbUi.OutcomePair', OutcomePair);

 })(window);